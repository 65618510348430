<template>
  <div>
    <div class="stream-box">
      <div
        ref="videoPlayer"
        class="vjs-theme-custom"
        @play="onPlayerPlay($event)"
        @pause="onPlayerPause($event)"
        @ended="onPlayerEnded($event)"
        @loadeddata="onPlayerLoadeddata($event)"
        @waiting="onPlayerWaiting($event)"
        @playing="onPlayerPlaying($event)"
        @timeupdate="onPlayerTimeupdate($event)"
        @canplay="onPlayerCanplay($event)"
        @canplaythrough="onPlayerCanplaythrough($event)"
        @ready="playerReadied"
        @statechanged="playerStateChanged($event)"
        v-video-player:videoPlayer="playerOptions"
      ></div>
    </div>
    <!-- <div class="queue-box">
      <div
        class="playlist-video-item"
        v-for="(video, id) in videos"
        :key="id"
        :class="id === currentPlaying ? 'is-highlighted' : ''"
      ></div>
    </div> -->
  </div>
</template>
<style lang="scss" scoped>
.queue-box {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: auto;
  margin: 1em 0;
}
.playlist-video-item {
  z-index: 10;
  overflow: hidden;
  border-radius: 0.5em;
  opacity: 0.35;
  margin: 0 0.35em;
  width: 100%;
  height: 0.5em;
  max-width: 3em;
  background: var(--color-subtle);
}
.is-highlighted {
  opacity: 1;

  background: var(--color-primary-alt);
}
.stream-box {
  position: relative;
  z-index: 1;
  // width: 100%;
  border-radius: 0.7em;
  box-shadow: 0px 3px 1.6px rgba(12, 11, 14, 0.031),
    0px 6.8px 3.6px rgba(12, 11, 14, 0.045),
    0px 11.8px 6.3px rgba(12, 11, 14, 0.056),
    0px 18.8px 10px rgba(12, 11, 14, 0.065),
    0px 29px 15.5px rgba(12, 11, 14, 0.074),
    0px 45.2px 24.1px rgba(12, 11, 14, 0.085),
    0px 75.1px 40px rgba(12, 11, 14, 0.099),
    0px 150px 80px rgba(12, 11, 14, 0.13);
  overflow: hidden;
  width: 100%;
  background: var(--bg);

  // height: var(--streamHeight); // height: 300px;
  // max-height: 100%;
  // height: 500px;
  // height: 80vw;
  // position: relative;
  // border: 10px solid white;
  // width: 300px !important;
  // height: 600px;
  .video-js {
    position: absolute !important;
    top: 0;
    left: 0;
    // position: unset !important;
    // display: block;
    // width: 100%;
  }

  .err-hero {
    position: absolute;
    z-index: 3;
    height: 100%;
    width: 100%;
  }
}
</style>

<script>
// import videojs from "video.js";
import "video.js/dist/video-js.css";
import "videojs-playlist/dist/videojs-playlist.js";

export default {
  name: "videoPlayer",
  props: {
    mutableNowPlaying: { type: Number, default: 0 },
  },
  data() {
    return {
      currentPlaying: this.mutableNowPlaying,
      // component options
      // width: "100%",
      // videojs options
      // videos: [{ index: 1 }, { index: 2 }, { index: 3 }, { index: 4 }],

      videos: [
        {
          sources: [
            {
              src: "https://storage.googleapis.com/tdfp-assets/muro-della-gentilezza.mp4",
              type: "video/mp4",
            },
          ],
        },
        {
          sources: [
            {
              src: "https://storage.googleapis.com/tdfp-assets/legalizetempio.mp4",
              type: "video/mp4",
            },
          ],
        },
        {
          sources: [
            {
              src: "https://storage.googleapis.com/tdfp-assets/we-are-the-resistance.mp4",
              type: "video/mp4",
            },
          ],
        },
        {
          sources: [
            {
              src: "https://storage.googleapis.com/tdfp-assets/340h-longest-djset.mp4",
              type: "video/mp4",
            },
          ],
        },
        // {
        //   sources: [
        //     {
        //       src: "https://storage.googleapis.com/tdfp-assets/video-interviews.mp4",
        //       type: "video/mp4",
        //     },
        //   ],
        // },
      ],
      playerOptions: {
        muted: true,
        autoplay: true,
        fluid: true,
        fill: true,
        // width: 600,
        live: false,
        liveui: false,
        playsinline: true,
        personalbar: false,
        // aspectRatio: "16:9",
        // language: "en",
        responsive: true,
        breakpoints: {
          tiny: 300,
          xsmall: 400,
          small: 500,
          medium: 600,
          large: 700,
          xlarge: 800,
          huge: 900,
        },
        aspectRatio: "16:9",
        // playbackRates: [0.7, 1.0, 1.5, 2.0],
        // sources: this.videos,
        // flash: { hls: { withCredentials: false } },
        // html5: { hls: { withCredentials: false } },

        // poster:
        //   "./poster.jpg",
      },
    };
  },
  // created() {

  // },
  mounted() {
    // this.currentPlaying = this.player.actualPlayingIndex;
    // console.log("this is current player instance object");
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
      // this.player = this.$refs.videoPlayer.player.width = "300px";
    },
  },
  methods: {
    // listen event
    onPlayerPlay(player) {
      // console.log(this.currentPlaying);
      // console.log(player.playlist.currentItem());
      // this.currentPlaying = player.playlist.currentItem(2);
      // console.log("player play!", player);
    },
    onPlayerPause(player) {
      // console.log('player pause!', player)
    },
    onPlayerEnded(player) {
      // console.log("player ended!", player);
      // restart the playlist

      if (player.playlist.currentItem() === this.videos.length - 1) {
        player.playlist.first();
      }

      //  else {
      //   player.playlist.next();
      // }
    },
    onPlayerLoadeddata(player) {
      // $(".vjs-custom-theme").show();
      // $(".stream-box").fadeTo("slow", 1);

      $(".vjs-theme-custom").fadeTo("slow", 1);
      // console.log(`loaded video number ${player.playlist.currentItem()}`);
      // this.currentPlaying = player.playlist.currentItem();

      // console.log(this.currentPlaying);
      // console.log(player.playlist.currentItem());
      // console.log(player.playlist.currentItem());
      // console.log(this.videos.length);
      // console.log(player.playlist.currentItem() === this.videos.length - 1);
      // if (player.playlist.currentItem() === this.videos.length - 1) {
      //   player.playlist.
      // }
      // console.log('player Loadeddata!', player)
    },
    onPlayerWaiting(player) {
      $(".vjs-big-play-button").hide();
      // console.log("player Waiting!", player);
      // $(".stream-box").fadeTo("slow", 0.35);
      // $(".vjs-theme-custom").fadeTo("slow", 0);
    },
    onPlayerPlaying(player) {
      // $(".vjs-theme-custom").fadeTo("slow", 1);
    },
    onPlayerTimeupdate(player) {
      // console.log('player Timeupdate!', player.currentTime())
    },
    onPlayerCanplay(player) {
      // console.log('player Canplay!', player)
    },
    onPlayerCanplaythrough(player) {
      // console.log('player Canplaythrough!', player)
    },

    // or listen state event
    playerStateChanged(playerCurrentState) {
      // console.log("player current update state", playerCurrentState.error);
      if (playerCurrentState.error === true) {
        $(".vjs-big-play-button").hide();
        // console.log("player Waiting!", player);
        // $(".stream-box").fadeTo("slow", 0.35);
        // $(".vjs-theme-custom").fadeTo("slow", 0);
        $(".vjs-modal-dialog").hide();
        $(".viewer-3d").show();
        $(".vjs-loading-spinner").hide();
      }
    },

    // player is ready
    playerReadied(player) {
      player.playlist(this.videos);

      player.playlist.autoadvance(1);
    },
  },
};
</script>
